<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />
    <b-card>
      <b-row class="mb-2">
        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date From</label>
            <b-input
              id="date_from"
              v-model="filter.date_from"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="date_from">Date To</label>
            <b-input
              id="date_from"
              v-model="filter.date_to"
              type="date"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Filter by Status</label>
            <b-select
              v-model="filter.status"
              :options="list.statuses"
              @change="onFilterChange"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(departure)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.departure_date} ${data.item.departure_time}`) }}
          </div>
        </template>

        <template #cell(arrival)="data">
          <div class="text-nowrap">
            {{ dateTimeFormatterLong(`${data.item.arrival_date} ${data.item.arrival_time}`) }}
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-nowrap">
            <b-button
              size="sm"
              @click="onShowViewForm(row.item)"
            >
              View
            </b-button>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <!-- Modal View Form -->
    <b-modal
      id="modal-view-form"
      scrollable
      no-close-on-backdrop
      title="View Detail"
    >
      <b-card no-body>
        <form
          novalidate
          role="form"
        >
          <b-tabs>
            <b-tab
              title="Reservation Details"
              active
            >
              <b-form-group>
                <label for="reserved_at">Reserved At</label>
                <b-input
                  id="reserved_at"
                  v-model="reservationHistory.created_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="status">Status</label>
                <b-input
                  id="status"
                  v-model="reservationHistory.status"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservationHistory.status === 'Cancelled'">
                <label for="cancellation_reason">Cancellation Reason</label>
                <b-textarea
                  id="cancellation_reason"
                  v-model="reservationHistory.cancellation_reason"
                  type="text"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="company">Company</label>
                <b-input
                  id="company"
                  v-model="reservationHistory.company_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="organization">Organization</label>
                <b-input
                  id="organization"
                  v-model="reservationHistory.organization_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="reservation_type">Reservation Type</label>
                <b-input
                  id="reservation_type"
                  v-model="reservationHistory.reservation_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_type">Vehicle Type</label>
                <b-input
                  id="vehicle_type"
                  v-model="reservationHistory.vehicle_type_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservationHistory.reservation_type === 'Service Vehicle'">
                <label for="passenger_count">Passenger Count</label>
                <b-input
                  id="passenger_count"
                  v-model="reservationHistory.passenger_count"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="departure">Departure</label>
                <b-input
                  id="departure"
                  v-model="departureDateTime"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="arrival">Arrival</label>
                <b-input
                  id="arrival"
                  v-model="arrivalDateTime"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="purpose">Purpose</label>
                <b-textarea
                  id="purpose"
                  v-model="reservationHistory.purpose"
                  type="text"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                <label for="processed_at">Processed At</label>
                <b-input
                  id="processed_at"
                  v-model="reservationHistory.processed_at"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                <label for="processed_by_name">Processed By</label>
                <b-input
                  id="processed_by_name"
                  v-model="reservationHistory.processed_by_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group v-if="reservationHistory.status !== 'Cancelled'">
                <label for="remarks">Processed Remarks</label>
                <b-textarea
                  id="remarks"
                  v-model="reservationHistory.processed_remarks"
                  type="text"
                  rows="6"
                  max-rows="6"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab>

            <b-tab
              v-if="reservationHistory.attachments.length > 0"
              title="Attachments"
            >
              <b-table
                class="mt-1"
                :fields="attachmentTableSettings.fields"
                :items="reservationHistory.attachments"
                responsive
                striped
                hover
              >
                <template #cell(actions)="row">
                  <div class="text-nowrap">
                    <b-button
                      size="sm"
                      class="mr-1"
                      @click="onPreviewAttachment(row.item)"
                    >
                      Preview
                    </b-button>
                  </div>
                </template>
              </b-table>
            </b-tab>

            <b-tab
              v-if="reservationHistory.status === 'Approved' && isDriverVisible"
              title="Driver Details"
            >
              <b-form-group>
                <label for="driver_name">Driver Name</label>
                <b-input
                  id="driver_name"
                  v-model="reservationHistory.driver_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_type">License Type</label>
                <b-input
                  id="driver_license_type"
                  v-model="reservationHistory.driver_license_type"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_number">License Number</label>
                <b-input
                  id="driver_license_number"
                  v-model="reservationHistory.driver_license_number"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="driver_license_expiry">License Expiry</label>
                <b-input
                  id="driver_license_expiry"
                  v-model="reservationHistory.driver_license_expiry"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>
            </b-tab>

            <b-tab
              v-if="reservationHistory.status === 'Approved' && isVehicleVisible"
              title="Vehicle Details"
            >
              <b-form-group>
                <label for="vehicle_name">Vehicle Name</label>
                <b-input
                  id="vehicle_name"
                  v-model="reservationHistory.vehicle_name"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_license_plate">License Plate</label>
                <b-input
                  id="vehicle_license_plate"
                  v-model="reservationHistory.vehicle_license_plate"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_registration_expiry">Registration Expiry</label>
                <b-input
                  id="vehicle_registration_expiry"
                  v-model="reservationHistory.vehicle_registration_expiry"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

              <b-form-group>
                <label for="vehicle_seat_capacity">Seat Capacity</label>
                <b-input
                  id="vehicle_seat_capacity"
                  v-model="reservationHistory.vehicle_seat_capacity"
                  type="text"
                  :disabled="true"
                />
              </b-form-group>

            </b-tab>

          </b-tabs>
        </form>
      </b-card>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>

    <!-- modal preview image -->
    <b-modal
      id="modal-preview-image"
      scrollable
      no-close-on-backdrop
      :title="`Preview Attachment [${file.name}]`"
      size="lg"
    >
      <div class="d-flex justify-content-center">
        <b-img
          fluid
          :src="file.preview"
        />
      </div>

      <template #modal-footer="{ cancel }">
        <b-button
          variant="danger"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { UserReportService } from '@/services'
import formatter from '@/mixins/formatter'
import url from '@/mixins/url'

export default {
  name: 'UserReportsReservationHistories',

  middleware: ['auth', 'user'],

  metaInfo () {
    return {
      title: 'Reports - Reservation Histories'
    }
  },

  mixins: [formatter, url],

  data () {
    return {
      state: {
        file: {
          previewing: false
        }
      },
      file: {
        name: '',
        preview: null
      },
      list: {
        statuses: [
          { text: 'All', value: 'All' },
          { text: 'Approved', value: 'Approved' },
          { text: 'Disapproved', value: 'Disapproved' },
          { text: 'Cancelled', value: 'Cancelled' }
        ]
      },
      filter: {
        status: 'All',
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now())
      },
      reservationHistory: {
        id: 0,
        created_at: '',
        status: '',
        user_name: '',
        company_name: '',
        organization_name: '',
        reservation_type: '',
        departure_date: '',
        departure_time: '',
        arrival_date: '',
        arrival_time: '',
        vehicle_type_name: '',
        passenger_count: 0,
        purpose: '',
        vehicle_name: '',
        vehicle_license_plate: '',
        vehicle_registration_expiry: '',
        vehicle_seat_capacity: 0,
        driver_name: '',
        driver_license_type: '',
        driver_license_number: '',
        driver_license_expiry: '',
        cancelled_at: '',
        cancellation_reason: '',
        processed_at: '',
        processed_by_name: '',
        processed_remarks: '',
        file_path: '',
        attachments: []
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'actions',
          { key: 'created_at', formatter: this.dateTimeFormatter, label: 'reserve at', sortable: true },
          'reservation_type',
          { key: 'vehicle_type_name', label: 'Vehicle Type' },
          'departure',
          'arrival',
          'status'
        ]
      },
      attachmentTableSettings: {
        fields: [
          'actions',
          { key: 'file_original_name', label: 'filename' }
        ]
      }
    }
  },

  computed: {
    departureDateTime () {
      if (this.reservationHistory.departure_date !== '' && this.reservationHistory.departure_time !== '') {
        return this.dateTimeFormatterLong(`${this.reservationHistory.departure_date} ${this.reservationHistory.departure_time}`)
      }
      return ''
    },

    arrivalDateTime () {
      if (this.reservationHistory.arrival_date !== '' && this.reservationHistory.arrival_time !== '') {
        return this.dateTimeFormatterLong(`${this.reservationHistory.arrival_date} ${this.reservationHistory.arrival_time}`)
      }
      return ''
    },

    isDriverVisible () {
      return ['Service Driver', 'Service Driver And Vehicle'].includes(this.reservationHistory.reservation_type)
    },

    isVehicleVisible () {
      return ['Service Vehicle', 'Service Driver And Vehicle'].includes(this.reservationHistory.reservation_type)
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await UserReportService.getReservationHistories(`page=${ctx.currentPage}&per_page=${ctx.perPage}&sort=${ctx.sortBy}&sort_desc=${ctx.sortDesc}&filter_text=${ctx.filter}&filter_status=${this.filter.status}&filter_from=${this.filter.date_from}&filter_to=${this.filter.date_to}`)
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    onShowViewForm (item) {
      this.reservationHistory.id = item.id
      this.reservationHistory.status = item.status
      this.reservationHistory.user_name = item.user_name
      this.reservationHistory.company_name = item.company_name
      this.reservationHistory.organization_name = item.organization_name
      this.reservationHistory.reservation_type = item.reservation_type
      this.reservationHistory.departure_date = item.departure_date
      this.reservationHistory.departure_time = item.departure_time
      this.reservationHistory.arrival_date = item.arrival_date
      this.reservationHistory.arrival_time = item.arrival_time
      this.reservationHistory.vehicle_type_name = item.vehicle_type_name
      this.reservationHistory.passenger_count = item.passenger_count
      this.reservationHistory.purpose = item.purpose
      this.reservationHistory.vehicle_name = item.vehicle_name
      this.reservationHistory.vehicle_license_plate = item.vehicle_license_plate
      this.reservationHistory.vehicle_registration_expiry = item.vehicle_registration_expiry
      this.reservationHistory.vehicle_seat_capacity = item.vehicle_seat_capacity
      this.reservationHistory.driver_name = item.driver_name
      this.reservationHistory.driver_license_type = item.driver_license_type
      this.reservationHistory.driver_license_number = item.driver_license_number
      this.reservationHistory.driver_license_expiry = item.driver_license_expiry
      this.reservationHistory.cancelled_at = item.cancelled_at !== null ? this.dateTimeFormatter(item.cancelled_at) : ''
      this.reservationHistory.cancellation_reason = item.cancellation_reason
      this.reservationHistory.processed_at = item.processed_at !== null ? this.dateTimeFormatter(item.processed_at) : ''
      this.reservationHistory.processed_by_name = item.processed_by_name
      this.reservationHistory.processed_remarks = item.processed_remarks
      this.reservationHistory.created_at = this.dateTimeFormatter(item.created_at)
      this.reservationHistory.file_path = item.file_path
      this.reservationHistory.attachments = item.attachments
      this.$bvModal.show('modal-view-form')
    },

    onFilterChange () {
      this.$refs.table.refresh()
    },

    onPreviewAttachment (item) {
      this.file.name = item.file_original_name
      this.file.preview = this.getImageUrl(`${this.reservationHistory.file_path}/${item.file_hash_name}`)
      this.$bvModal.show('modal-preview-image')
    }
  }
}
</script>
